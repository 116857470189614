/*global window, document, getSiblings ,setInterval, clearInterval,getElements,getElement,getNextSibling,getPrevSibling,setAttributes,getComputedStyle,pageDirection,console*/
/*jslint es6 */
/*=== Theme Customize ===*/
document.addEventListener('DOMContentLoaded', function () {
      //===== Hero Slider =====//
      var hero_slider = getElement('.hero-slider');
      if (hero_slider) {
          var heroSlider = tns({
              container: hero_slider,
              items: 1,
              autoplay: true,
              nav: false,
              autoplayButton: false,
              autoplayButtonOutput: false,
              controls: false,
              responsive: {
                  768: {
                      controls: true
                  }
              },
          });
          hero_slider.closest('.tns-outer').classList.add('hero-slider-outer');
      };
  
});